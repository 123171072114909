/**
 * Created by astimlee on 12/10/2017.
 */
export class Order {

  constructor(public orderCode:any,
              public name:any,
              public email:any,
              public basketTotal:any,
              public shippingTotal:any,
              public total:any,
              public balance:any,
              public addressCode:any,
              public paid:boolean,
              public delivered:boolean,
              public reference:any,
              public note:any,
              public tracking: any,
              public dateAdded:any
  ) {

    this.orderCode = orderCode;
    this.name = name;
    this.email = email;
    this.basketTotal = basketTotal;
    this.shippingTotal = shippingTotal;
    this.total = total;
    this.balance = balance;
    this.addressCode = addressCode;
    this.paid = paid;
    this.delivered = delivered;
    this.reference = reference;
    this.note = note;
    this.tracking = tracking;
    this.dateAdded = dateAdded;

  }

}
