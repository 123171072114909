import { Http, RequestOptions } from "@angular/http";
import { Injectable } from "@angular/core";
import "rxjs/add/operator/map";

import { Product } from "../models/product";
import { BagItem } from "../models/bag-item";
import { OrderLine } from "../models/order-line";
import { OrderAddress } from "../models/orderAddress";
import { Order } from "../models/order";

/*
  Generated class for the BulaServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/
@Injectable()
export class BulaService {
  apiURL = "https://bulabro.com/script/";
  imageURL = "https://bulabro.com/images/";

  products: Array<Product> = new Array<Product>();
  shoppingBag: Array<BagItem> = new Array<BagItem>();
  orderLines: Array<OrderLine> = new Array<OrderLine>();
  orderedBag: Array<BagItem> = new Array<BagItem>();
  colors: Array<any> = new Array<any>();
  images: Array<any> = new Array<any>();
  orderedReference: any;

  currency: any;
  country: any;
  shipping: any;
  addditional: any;
  tracking:any;
  
  orderAddress: OrderAddress;
  order: Order;

  product2Open: any;

  showLoading: any = false;
  visitorDataLink = "https://ipinfo.io/json";

  localStorageKey = "__Bulabro__";

  shopBannerMessage: any = `Free Shipping Worldwide for orders over $250`;
  generalMessage: any = `We are currently experiencing unusual delays with our delivery services worldwide.\n\nThank you for your patience`;
  generalMessageCounter: any = 0;

  categories = [
    { name: "MENS", image: "assets/img/m.png" },
    { name: "WOMENS", image: "assets/img/w.png" },
    { name: "HATS", image: "assets/img/h.png" },
    { name: "KAJI", image: "assets/img/k.png" },
  ];

  coupons = [{ code: "BB20", rate: 20, valid: true }];
  currentCoupon: any = null;

  constructor(public http: Http) {
    console.log("bula service ons");

    
    this.http
      .get("https://ipinfo.io/json")
      .map((res) => res.json())
      .subscribe((data) => {
        this.saveVisitor(data);
        this.setupLocation(data.country);
      });
      
     //this.setupLocation("US");

    this.orderAddress = new OrderAddress(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.order = new Order(
      "",
      "",
      "",
      0.0,
      0.0,
      0.0,
      0.0,
      "",
      false,
      false,
      "",
      "",
      "",
      ""
    );

    this.getColors();
    this.getProductImages();
  }

  getVisitorData(link) {
    return this.http.get(link).map((res) => res.json());
  }

  setupLocation(country) {
    this.country = country;
    this.getCurrency().subscribe((data) => {
      
      if (data) {
        this.currency = data[0].currency;
        this.shipping = data[0].shipping;
        this.addditional = data[0].additional;
        this.tracking = data[0].tracking;
      } else {
        console.log("cannot proccess location");
        this.setupLocation("US");
      }
      
    });
  }

  getCurrency() {
    var parameters = JSON.stringify({
      country: this.country,
    });

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "getCurrency.php";

    return this.http.post(url, body, options).map((res) => res.json());
  }

  reset() {
    this.orderedBag = Object.assign([], this.shoppingBag);
    this.orderedReference = this.order.reference;
    this.shoppingBag.length = 0;
    this.orderLines.length = 0;
    this.orderAddress = new OrderAddress(
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );
    this.order = new Order(
      "",
      "",
      "",
      0.0,
      0.0,
      0.0,
      0.0,
      "",
      false,
      false,
      "",
      "",
      false,
      ""
    );
  }

  getProducts() {
    return this.http
      .get(this.apiURL + "getProducts.php")
      .map((res) => res.json());
  }

  getColors() {
    this.http
      .get(this.apiURL + "getColors.php")
      .map((res) => res.json())
      .subscribe((data) => {
        this.colors = data;
      });
  }

  getProductImages() {
    this.http
      .get(this.apiURL + "getProductImages.php")
      .map((res) => res.json())
      .subscribe((data) => {
        this.images = data;
      });
  }

  getStockNumbers(productCode) {
    var parameters = JSON.stringify({
      productCode: productCode,
    });

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "getStockNumbers.php";

    return this.http.post(url, body, options).map((res) => res.json());
  }

  saveVisitor(data) {
    var parameters = JSON.stringify({
      ip: data.ip,
      city: data.city,
      country: data.country,
      location: data.loc,
    });

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "saveVisitor.php";

    this.http
      .post(url, body, options)
      .map((res) => res)
      .subscribe((data) => {});
  }

  saveProductVisitor(parameters) {
    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "saveProductVisitor.php";

    this.http
      .post(url, body, options)
      .map((res) => res)
      .subscribe((data) => {});
  }

  getBagSize() {
    let tempSize = 0;

    for (let bagItem of this.shoppingBag) {
      tempSize = tempSize + bagItem.quantity;
    }

    return tempSize;
  }

  getSubtotal() {
    let tempTotal = 0.0;

    if (this.shoppingBag.length > 0) {
      for (const bagItem of this.shoppingBag) {
        const product = this.products.filter(
          (item) => item.code === bagItem.productCode
        )[0];
        tempTotal =
          tempTotal + Number(product.price) * Number(bagItem.quantity);
      }
    } else {
      tempTotal = 0.0;
    }

    return tempTotal.toFixed(2);
  }

  saveAddress(address) {
    var parameters = JSON.stringify(address);

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "saveAddress.php";

    return this.http
      .post(url, body, options)
      .map((response) => response.json());
  }

  saveOrderLine(orderLine) {
    var parameters = JSON.stringify(orderLine);

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "saveOrderLine.php";

    return this.http
      .post(url, body, options)
      .map((response) => response.json());
  }

  saveOrder(order) {
    var parameters = JSON.stringify(order);

    let body: string = parameters,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "saveOrder.php";

    return this.http
      .post(url, body, options)
      .map((response) => response.json());
  }

  generateCode(type) {
    //new user code

    var text = "";
    var possible =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

    for (var i = 0; i < 50; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length));
    }

    text = type + text;

    return text;
  }

  generateReference() {
    return this.http
      .get(this.apiURL + "getInvoiceNumber.php")
      .map((res) => res.json());
  }

  generatePDF(data2send) {
    let body: string = data2send,
      type: string = "application/x-www-form-urlencoded; charset=UTF-8",
      headers: any = new Headers({ "Content-Type": type }),
      options: any = new RequestOptions({ headers: headers }),
      url: any = this.apiURL + "tcpdf/bulabro/emailQuote.php";

    return this.http.post(url, body, options).map((response) => response);
  }

  // triggered only after products are loaded;
  loadLocalStorage() {
    this.shoppingBag =
      JSON.parse(localStorage.getItem(this.localStorageKey)) || [];
  }

  resetLocalStorage() {
    localStorage.setItem(this.localStorageKey, JSON.stringify([]));
  }

  updateLocalStorage() {
    localStorage.setItem(
      this.localStorageKey,
      JSON.stringify(this.shoppingBag)
    );
  }
}
