/**
 * Created by astimlee on 12/10/2017.
 */
export class OrderAddress {

  constructor(
    public addressCode:any,
    public orderCode:any,
    public firstName:any,
    public lastName:any,
    public email:any,
    public phone:any,
    public country:any,
    public address:any,
    public city:any,
    public zipCode:any


  ) {

    this.addressCode = addressCode;
    this.orderCode = orderCode;
    this.firstName = firstName;
    this.lastName = lastName;
    this.email = email;
    this.phone = phone;
    this.country = country;
    this.address = address;
    this.city = city;
    this.zipCode = zipCode;
  }

}
